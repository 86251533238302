.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: rgb(34, 34, 34);
  overflow: hidden;
}


.content {
  flex: 1 0 auto;
}


.App-logo {}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {}

.App-link {}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}