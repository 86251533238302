.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	box-sizing: border-box;
}

.petmodelWrap2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 10px;
	gap: 0px;
	border-radius: 10px;
	box-shadow: 1px 1px 5px grey;
	width: 500px;
	max-width: 90%;
	box-sizing: border-box;
}
.promptText {
  display: flex;
  flex-direction: column;
	font-size: 16px;
	color: #333;
	margin-bottom: 10px;
	text-align: center;
	padding: 10px;
	/* background-color: #f5f5f5; */
	border-radius: 5px;
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}
.copyBut{
	background-color: #f0f0f0;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 12px;
	color: #333;
	cursor: pointer;
	margin: 10px;
	transition: background-color 0.3s ease;
}
.map {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	padding: 20px;
	max-width: 100%;
	box-sizing: border-box;
}
.imagePlaceholder {
	width: 300px;
	height: 300px;
	background-color: #f0f0f0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: #666;
	text-align: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 10px;
}
.saveImageBut {
	background-color: rgba(255, 255, 255, 0.8);
	border: none;
	border-radius: 5px;
	padding: 8px 15px;
	font-size: 14px;
	color: #333;
	cursor: pointer;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 10px;
	transition: background-color 0.3s ease;
}
.promptImageWrap {
	width: 300px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease-in-out;
}
.promptImage {
	width: 300px;
	height: auto;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease-in-out;
}
.viewButton {
	align-self: center;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	width: 99%;
	border: 2px solid white;
	background-color: transparent;
	cursor: pointer;
	padding: 10px;
	margin: 2px;
	border-radius: 10px;
	font-weight: bold;
	color: white;
	text-transform: capitalize;
	background: linear-gradient(90deg, #1cb5e0 10%, #94116fc1 90%);
}

.form {
	align-self: center;
	border: 1px solid grey;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 1px 1px 5px grey;
	margin: 10px;

	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 10px;
	align-items: center;
	width: 600px;
	max-width: 90%;
	box-sizing: border-box;
}
.success {
	color: green;
	font-weight: bold;
	font-size: 16px;
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	text-align: center;
	width: 100%;
	border-radius: 10px;
	padding: 10px;
	margin: 0px;
	box-sizing: border-box;
}

.error {
	color: rgb(255, 208, 0);
	font-weight: bold;
	font-size: 16px;
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	text-align: center;
	width: 100%;
	border-radius: 10px;
	padding: 10px;
	margin: 0px;
	box-sizing: border-box;
}

.labelPrompt {
	width: 100%;
}

.form > label {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	font-weight: bold;
	font-size: 16px;
	color: #2a072a;
	margin: 0px;
	box-sizing: border-box;
}

.form > label > input {
	width: 100%;
	padding: 5px;
	border-radius: 10px;
	border: 2px solid #1cb5e0;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	font-size: 16px;
	color: #2a072a;
}
.form > label > textarea {
	width: 100%;
	padding: 5px;
	border-radius: 10px;
	border: 2px solid #1cb5e0;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	font-size: 16px;
	color: #2a072a;
}
.form > label > select {
	width: 100%;
	padding: 5px;
	border-radius: 10px;
	border: 2px solid #1cb5e0;
	box-sizing: border-box;
	font-size: 16px;
	color: #2a072a;
}

.submitButton {
	/* align-self: center; */
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	width: 99%;
	border: 2px solid white;
	background-color: transparent;
	cursor: pointer;
	padding: 7px;
	margin: 2px;
	border-radius: 10px;
	font-weight: bold;
	color: white;
	text-transform: capitalize;
	background: linear-gradient(90deg, #1cb5e0 10%, #94116fc1 90%);
}
.submitButton:hover {
	box-shadow: 0 0 0 0;
	border: 2px solid #1cb5e0;
}
.saveImageBut {
	transition: all 0.3s ease;
}

.saveImageBut:hover {
	background-color: #1cb5e0;
	color: white;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.saveImageBut:active {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
	.promptImageWrap {
		width: 100%;
	}

	.promptImage {
		width: 100%;
		max-height: 300px;
		object-fit: cover;
	}

	.saveImageBut {
		width: 70%;
		max-width: none;
	}
}

@media (max-width: 768px) {
	.wrapper {
		padding: 10px;
	}

	.form {
		width: 100%;
	}

	.form > label > input,
	.form > label > textarea,
	.form > label > select {
		width: 100%;
		font-size: 14px;
	}

	.submitButton {
		width: 100%;
		padding: 10px;
		font-size: 16px;
	}

	.map {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.promptImageWrap {
		width: 100%;
		margin-bottom: 15px;
	}

	.promptImage {
		width: 100%;
		height: auto;
	}

	.saveImageBut {
		width: 70%;
		margin-top: 5px;
	}
}
