.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0px;
  margin: 0px;
}

h4 {
  margin: 5px;
  padding: 5px;
}

.sampleImage {
  width: 70px;
  height: 84px;
  border-radius: 5px;
  margin: 0px;
}

.arrowIcon {
  width: 30px;
  height: 30px;
  filter: grayscale(0.5);
}

.span {
  font-size: 50px;
  display: flex;
  gap: 5px;
  align-items: center;
}

@media screen and (max-width: 1200px) {


  .sampleImage {
    width: 60px;
    height: 72px;
  }

  /* .wrapper {
    justify-content: center;
  } */

  .h4 {
    margin: 1px;
    padding: 1px;
  }
}

@media screen and (max-width: 800px) {
.wrapper {
  margin-left: 5px;
  gap: 2px;
}
}