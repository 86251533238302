.navWrapNormal {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 5px;
  z-index: 99999;
  bottom: 0;
  position: fixed;
  padding: 0px;
  left: 0;
  border-radius: 20px;
  /* max-height: 20px; */
  width: 75%;
  /* border: 7px solid red; */
  box-sizing: border-box;
  /* background: rgba(222, 222, 222, 0.525); */
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to left, #cbcaa5, #334d50bc); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to left, #cbcaa5, #334d50bc); */
  /* border: 1px solid white; */

}

.navWrap>* {
  color: white;
}

.navWrapNormalOld {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 5px;
  z-index: 99999;
  bottom: 0;
  position: fixed;
  padding: 0px;
  left: 0;
  border-radius: 20px;
  /* max-height: 20px; */

  box-sizing: border-box;
  background: rgba(222, 222, 222, 0.525);
}

.navlinkStarted {
  display: flex;
  text-decoration: none !important;
  border-radius: 20px;
  /* background: linear-gradient(90deg, #d3e01c 50%, #f69103c1 90%); */
  align-self: center;
  margin: 0px;
  width: 50vw;
  background: rgba(222, 222, 222, 0.525);

}

.getStarted {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  background: linear-gradient(90deg, #1CB5E0 10%, #94116fc1 90%);
  border: 1px solid #f5f5f5;
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin: 6px;
  cursor: pointer;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;

  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.getStarted:hover{
  transform: scale(1.05);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 25px;
  height: 25px;
  filter: invert(1);
}

.logoarea {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.phone {
  text-decoration: none;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  padding: 5px;
}

.phone:hover {
  border-radius: 5px;
}


.navWrap>p {
  font-weight: bold;
}

.logo {
  margin: 0px;
  width: 35px;
  height: 35px;
  border-radius: 15px;
  margin: 5px;
  /* border: 2px solid white; */
  padding: 7px;
}

.menuButton {
  flex: 1 0 21%;
  /* border: 1px solid red; */
  border-right: 1px solid white;
  padding: 5px;
  background-color: transparent;
  font-weight: bolder;

  color: white;
  text-decoration: none;
  /* border: none; */
}

.messInput {

  border: 1px solid white;
  cursor: pointer;
  /* border: none; */
  padding: 5px;
  font-size: 15px;
  background-color: transparent;
  font-weight: bolder;
  max-width: 100%;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-weight: 450;
  width: 350px;
  max-width: 350px;
  max-height: 350px;
}

.menuButton1 {
  align-self: center;
  cursor: pointer;
  border: none;
  /* padding: 10px; */
  background-color: transparent;
  border-radius: 20px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  display: flex;
  font-weight: 450;
  padding: 10px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: 1px solid white;

  /* border: 1px solid rgba(255, 255, 255, 0.731); */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #cbcaa5c9, #334d50c7);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #cbcaa5c9, #334d50c2);
}

.menuButton1:hover {
  transform: scale(1.05);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 13px;
  /* padding: 5px; */
  /* padding-bottom: 5px */
}


.icon {
  width: 35px;
  height: 35px;
  padding: 0px;
  filter: invert(90%);
}

.labelWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* width: 35vw; */
  align-items: center;
  color: white;
  font-weight: bold;
  background: #334d50ba;
  padding: 10px;
  border-radius: 15px;
}

.labelWrap2 {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* width: 35vw; */
  align-items: center;
  color: white;
  font-weight: bold;
}

.sendButton {
  width: 50%;
  padding: 5px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid white;
  background: #334d5061;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #cbcaa5, #334d50);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #cbcaa5, #334d50);

}

.closeButton {
  width: 30%;
  padding: 5px;
  color: white;
  font-weight: 300;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid white;
  background: #334d507d;
}

@media (max-width: 768px) {
  .menuButton1 {
    padding-left: 10px;
    padding-right: 10px;
    max-height: 2.5rem;
  }

  .sendButton {
    width: 90%;
    padding: 20px;
    border-radius: 15px;
  }

  .closeButton {
    width: 70%;
    padding: 15px;
    border-radius: 15px;
  }

  .getStarted {
    font-size: 15px;
    height: 40px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

}