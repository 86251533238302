.faqContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

}

.faqHeading {
  font-size: 2em;
  margin-bottom: 20px;
}

.faqWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100vw;
}


.faqItemIn {
  padding: 20px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  text-align: left;
  transition: all 0.3s ease;
  margin: 10px;
  flex: 1 0 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(228, 228, 228);
}

.faqItem {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: left;
  transition: all 0.3s ease;
  margin: 10px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faqItemIn:hover,
.faqItem:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.faqQuestion {
  font-size: 1.2em;
  margin-bottom: 10px;
  text-align: center;
}

.faqAnswer {
  display: block;
  height: auto;
  text-align: center;
}

.faqAnswerIn {
  overflow: hidden;
  display: none;
  transition: height 0.3s ease;
}

@media screen and (max-width: 650px) {
  .faqItemIn {
    flex: 1 0 51%;
  }
}