@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.5s forwards;

}

.fadeOut {
  animation: fadeOut 0.5s forwards;

}

/* Testimonial.module.css */
.wrap {
  width: 95vw;
  box-sizing: border-box;

}

.testimonial {
  transition: transform .4s;
  display: flex;
  flex: 1 0 90%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  transition: all 0.3s ease;
  /* flex: 1 0 100%; */
  justify-content: center;
  box-sizing: border-box;
  margin: 0px;

}

.notActive {
  display: none;
}

.name {
  text-align: center;
  font-size: 1rem;
  color: rgba(23, 21, 25, 1);
  box-shadow: 0px 3px 10px #9e87d8;
  width: 90%;
  max-width: 90%;
  height: 100%;
  max-height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  align-self: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  border: 1px solid #6941d0;
}


.message {
  font-style: normal;
  font-size: 1.2rem;
  font-style: italic;
  margin: 10px;
}