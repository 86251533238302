.wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;

}

.breakH2 {
  display: none;
}

.bottomLinks {
  display: flex;
}

.h2 {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  max-width: 80vw;
  align-self: center;
}

@media screen and (max-width: 800px) {
  .h2 {
    max-width: 95vw;
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 480px) {
  .h2 {
    max-width: 100%;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.thumbdivone {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
  margin: 0px;
}

.glogo {
  width: 50px;
}

.seenImages {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.seenOn {
  width: 120px;
  height: auto;
}

.halfFil {
  background: linear-gradient(to right, orange 70%, white 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;

}

.fullfill {
  background: linear-gradient(to right, orange 50%, orange 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;

}

.googleRev {
  flex: 1 0 20%;
  margin: 0px;
  display: flex;
  margin-right: 20px;
  align-items: center;
  max-width: 50%;
  justify-content: center;
}

.googleRev>p {
  margin-right: 10px;
}

.googleRevMob {
  display: none;
}

.googleRevMob>p {
  margin-right: 10px;
}

.wraph1 {
  font-size: 22px;
  margin: 0px;
  margin-top: 7px;
  text-align: center;
  flex: 1 0 34%;
}


.mobUiCover {
  display: none;
}

.galleryLink {
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  margin: 2px;
  flex: 1 0 26%;
  cursor: pointer;
  color: black;
  border: 1px solid lightgray;
  background-color: white;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.galleryLink:hover {
  cursor: pointer;
  background-color: #1e90c9;
  border: 1px solid white;
  color: white;
  box-shadow: 3px 3px 5px lightgray;

}

.galleryLinkMob {
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  margin: 2px;
  color: black;
  cursor: pointer;
  border: 1px solid lightgray;
  background-color: white;
  flex: 1 0 26%;
  text-decoration: none;
  text-align: center;
  display: none;
}

.galleryLinkMob:hover {
  cursor: pointer;
  background-color: #1e90c9;
  border: 1px solid white;
  color: white;
  box-shadow: 3px 3px 5px lightgray;
}


.wraph1Mobile {
  font-size: 18px;
  margin: 0px;
  margin-top: 7px;
  display: none;
}

.wraph1>i {
  font-size: 22px;
  font-weight: 600;
  font-style: italic;
}


.youtube {
  flex: 1 0 51%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  margin: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: rgb(236, 236, 236); */
  border-radius: 15px;
}

.h4mob,
.thumbDivMob {
  display: none;
}


.iframeVideo {
  width: calc(50%);
  height: calc(50vw/1.7777);
  /* height: auto; */
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  margin: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: rgb(236, 236, 236); */
  border-radius: 15px;
}



.imagesGeneratedP {
  padding: 0px;
  margin: 0px;
  text-align: center;
  font-weight: 500;
  color: grey;
  font-style: italic;
}

.gallery {
  width: 700px;
  margin: 0px;
}

.section1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px;
  max-width: 100%;
  box-sizing: border-box;
}



.ppSup {
  font-size: 0.7rem;
}

.leftSec {
  display: flex;
  flex-direction: column;
  flex: 1 0 49%;
  box-sizing: border-box;

}

.smallBelowPrice {
  display: flex;
  align-self: flex-start;
  margin: 5px;
  font-size: 0.8rem;
  flex-wrap: wrap;
}

.rightFirstSec {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  width: 50%;
  gap: 20px;
  border-radius: 4px;
}


.coverImage {
  width: calc(100vw/2);
  height: calc((100vw/2)*1.15);
  align-self: center;
  box-sizing: border-box;
  transition: all 0.3s ease;
  max-width: 100%;
  border: 15px solid #059dcb;
  padding: 5px;
  box-shadow: 5px 5px 15px 5px rgb(49, 49, 49);
  background-color: white;
  cursor: pointer;
  object-fit: cover;
}

.coverImageSkelet {
  width: calc(100vw/2);
  height: calc((100vw/ 2)* 1.25);
  align-self: center;
  box-sizing: border-box;
  max-width: 100%;
  border: 20px solid #1e90c9;
  padding: 20px;
  box-shadow: 6px 6px 8px 0px black;
  background-color: lightgray;
  margin: 10px;
  /* object-fit: cover; */
}


.wrap>h2>p {
  font-size: 0.9rem;
  text-align: center;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}




.thumbDiv {
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  margin: 10px;
  gap: 2px;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex: 1 0 100%;
  max-width: 100vw;
  /* box-shadow: 1px 1px 7px black; */
}

.thumbDivHide {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  margin: 10px;
  gap: 2px;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex: 1 0 100%;
  max-width: 100vw;
}


.thumbnail:hover {
  transform: scale(1.05);
  box-shadow: 2px 2px 5px rgb(33, 86, 199);
  border: 2px solid white;
  filter: brightness(1.05);
  cursor: pointer;
}

.thumbnail {
  width: calc(100vw/20);
  height: calc(100vw/20*1.1);
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  margin-bottom: 2px;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px lightgray;
  border: 2px solid white;

  /* border-bottom: 5px solid lightgray; */
}


.thumbnailActive {
  width: calc(100vw/20);
  height: calc(100vw/20*1.1);
  padding: 0px;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  margin-bottom: 2px;

  border-radius: 5px;
  /* border-bottom: 5px solid rgb(33, 86, 199); */
  box-shadow: 2px 2px 5px rgb(33, 86, 199);
  border: 2px solid white;
  filter: brightness(1.05);
}


.icon {
  width: 25px;
  height: 25px;
  filter: invert(1);
}

.icon3 {
  width: 45px;
  height: 45px;
  filter: invert(0.5);
  cursor: pointer;
}

.icon4 {
  width: 45px;
  height: 45px;
  filter: invert(0.5);
  transform: rotate(180deg);

  cursor: pointer;

}



.icon2 {
  margin-bottom: 5px;
  width: 55px;
  height: 55px;
  filter: grayscale(1);
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  background-color: rgb(42, 7, 42);
  color: white;
}


.getStartedSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  max-width: 50%;
  width: 50%;
  align-self: center;
  height: 100%;
}



.describe,
small {
  align-self: center;
}

.describe {
  align-self: center;
  max-width: 100%;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 600;
  color: rgb(72, 72, 72);
  text-align: center;
  border-radius: 10px;
}


/* make italic */
.wrpah3 {
  text-align: center;
  margin: 3px;
  padding: 3px;
}

.wraph2 {
  margin: 1px;
  padding: 2px;
  font-size: large;
  margin-bottom: 0px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}


.caveMan {
  font-size: 1.15rem;
  letter-spacing: 1px;
  color: white;
  width: 80%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  background-image: linear-gradient(to bottom right,
      transparent 50%,
      #4158D0 50%),
    linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%),
    linear-gradient(to top left, transparent 50%, #FFCC70 50%);
  background-repeat: no-repeat;
  background-size: 10px 50px, calc(100% - 20px) 50px, 10px 50px;
  background-position: left center, center, right;

}

.inblue {
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
  justify-content: center;
  display: flex;
  color: white;
  width: 95%;
  padding: 10px 15px;
  font-size: 17px;
  background-image: linear-gradient(to bottom right,
      transparent 50%,
      #4158D0 50%),
    linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%),
    linear-gradient(to top left, transparent 50%, #FFCC70 50%);
  background-repeat: no-repeat;
  background-size: 10px 50px, calc(100% - 20px) 50px, 10px 50px;
  background-position: left center, center, right;
}

@media screen and (max-width: 800px) {
  .inblue {
    font-size: 15px;
    padding: 12px 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 480px) {
  .inblue {
    font-size: 13px;
    padding: 10px 15px;
    width: 85%;
    background-size: 8px 50px, calc(100% - 16px) 50px, 8px 50px;
  }
}

.thumbBut {
  border: none;
  align-self: flex-start;
  width: calc(100vw/28);
  height: calc(100vw/22*1.15);
  padding: 5px;
  box-sizing: border-box;
  margin: 2px;
  /* padding: 0px; */
  border-radius: 5px;
  /* border-bottom: 5px solid white; */
  cursor: pointer;
  background-color: white;
  color: black;
  font-weight: bolder;
  box-shadow: 1px 1px 2px gray;
  border: 2px solid black;
  border-bottom: 2px solid rgb(32, 146, 192);
}

.thumbBut:hover {
  background-color: black;
  color: white;
  font-weight: bolder;
  box-shadow: 2px 2px 2px gray;
  border-bottom: 2px solid rgb(0, 183, 255);

}

.inblue2 {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
  color: white;
  flex: 1 0 34%;
  background: linear-gradient(43deg, #4158D0 15%, #C850C0 70%, #FFCC70 95%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  align-items: center;
  margin: 0px;

}

.inblue2Strong {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  gap: 15px;
  /* border: 1px solid red; */
  width: 100%;
  /* font-size: 16px; */
  display: flex;
  font-size: 20px;
}

.sectio1 {
  margin: 0px;
}

.section1>div {
  padding: 5px;
}


.saveImageButton {
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
  color: black;
  font-weight: 600;
  border: 1px solid rgb(151, 151, 151);
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.saveImageButton:hover {
  background-color: black;
  color: white;
  transform: scale(1.15);
}



.howitworksImage {
  flex-grow: 1;
  max-width: 100%;
  max-height: fit-content;
  border-radius: 10px;
  transition: all 0.4s ease;
  box-sizing: border-box;
}

.imageSection1:hover {
  transform: scale(1.05);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
}


.fullpageActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 9999;

  width: 100%;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: white;
  /* background-image: url("../Assets/ai_background.webp"); */
  border-radius: 10px;
  transition: all 0.3s ease;
}

.exitButton {
  align-self: center;
  background-color: rgba(0, 0, 0, 0.823);
  color: white;
  border: 1px solid rgb(86, 198, 54);
  border-radius: 10px;
  height: 45px;
  width: 50%;
  cursor: pointer;
}

.exitButton:hover {
  background-color: rgb(255, 255, 255);
  color: black;
  border: 2px solid rgb(86, 198, 54);
  transition: all 0.3s ease;

}

/* .imageSection1>img {
  border-radius: 15px;
  max-width: 80%;
} */

.howtoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}




.howtoBox {
  display: flex;
  flex-grow: 1;
  align-self: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
}

.testimonials {
  width: 99vw;
  max-width: 99vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  background: rgb(236, 236, 236);
}



.featureUL {
  box-sizing: border-box;
  align-self: flex-start;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  margin: 0px;
}

.featureULItem:before {
  content: "+";
  margin-right: 5px;
  font-weight: bold;
}

.featureULItem {
  background-color: rgb(235, 235, 235);
  padding: 5px;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  flex: 1 0 40%;
  align-self: flex-start;
  text-align: left;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.featureWrap {
  align-self: center;
  max-width: 70vw;

}


/* Styling for the span within the service package */
.serviceSpanEx {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: white;
  width: 100%;
  box-sizing: border-box;
}

/* Styling for the label within the service package */
.spLabel {
  align-self: center;
  margin: 5px;
  font-weight: bold;
}




.priceGroup {
  display: flex;
  width: 100%;
}

.carousel {
  width: 100%;
  /* height: 875px; */
  /* min-height: 500px; */
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  flex-direction: column;
  /* background: linear-gradient(200deg, #1e90c9 0%, rgb(42, 7, 42) 100%); */
  /* padding: 25px; */
}

/* Styling for the unordered list within the span of the service package */
.itemsGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px;
  padding: 5px;
  justify-content: space-evenly;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(90deg, #1e90c9 10%, #1cca8ac1 90%);
}






.priceButton {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid rgb(26, 97, 190);
  margin: 10px;
  border-radius: 10px;
}

.priceButton>h4 {
  text-align: center;
}

/* Styling for the list items within the unordered list of the span within the service package */
.item {
  align-self: center;
  text-align: center;
  text-decoration: none;
  list-style-type: none;
  border-radius: 5px;
  background-color: white;
  font-weight: 800;
  display: flex;
  justify-content: center;
  flex: 1 0 34%;
  padding: 10px;
  margin: 10px;
}

.servicePackage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;
  margin: 10px;
  padding: 10px;
  /* border: 2px solid purple; */
  text-align: left;
  box-sizing: border-box;
}



.itemSmallBonus {
  align-self: center;
  text-align: center;
  text-decoration: none;
  list-style-type: none;
  background-color: rgba(46, 46, 46, 0.747);
  border-radius: 5px;
  /* background: linear-gradient(90deg, #e0a81c 10%, rgba(240, 95, 95, 0.798) 50%, rgba(255, 207, 35, 0.798) 90%); */
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex: 1 0 34%;
  padding: 3px;
  margin: 3px;
  color: white;
  max-width: 50vw;
  /* box-shadow: 3px 3px 10px grey; */
  margin-bottom: 5px;
  align-self: center;
}

.howtoStep {
  flex: 1 0 26%;
  border: 1px solid white;
  border-radius: 25px;
  transition: all 0.3s ease;
  text-align: center;
  background: white;
  /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.441); */
  color: rgb(50, 50, 50);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
  padding-bottom: 0px;
}



.howtoStep:hover {
  transform: scale(1.05);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
}





.bundlesWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.benefitsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;

}

.benefitsHeading {
  text-align: center;
}

.benefitsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  box-sizing: border-box;
  margin: 10px;

}

.benefitItem {
  flex: 1 0 26%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: left;
  padding-top: 5px;
}


/* .benefitItem:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
} */

.benefitHeading3 {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
  padding-top: 0px;
}

.benefitItem>p {
  font-size: 16px;
  text-align: center;
}

.howitworks1 {

  display: flex;
  flex-direction: column;
  max-width: 100%;
  background: rgb(237, 237, 237);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 20px;
}



.testimonialHeading {
  text-align: center;
  margin: 5px;
}



.howtoStep>p {
  text-align: center;
  width: 100%;
}


.sampleBut {
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid lightgray;
  flex: 1 0 30%;
  height: 100%;
  font-size: 1.05rem;
  cursor: pointer;
}

.sampleBut2 {
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  margin: 2px;
  flex: 1 0 16%;
  cursor: pointer;
  border: 1px solid lightgray;
  background-color: white;
}

.sampleDescribe {
  text-align: left;
  flex: 1 0 34%;
  padding: 5px;
  margin: 0px;

}

.sampleBut:hover {
  background-color: rgb(1, 104, 214);
  border: 1px solid rgb(235, 235, 235);
  color: white;
  font-weight: 500;
  transition: all 0.3s ease;
}


.sampleButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgb(232, 232, 232);
  align-items: center;
  border-radius: 15px;
  padding: 5px;
  max-width: 100vw;
  box-sizing: border-box;
  gap: 10px;
}

.buttonWrapSamples {
  flex: 1 0 50%;
  display: flex;
}



@media screen and (min-width: 1700px) {

  .section1 {
    justify-content: space-around;
  }


}

.howitworks1Mob {
  display: none;
}


@media screen and (max-width: 1200px) {
  .h4mob {
    display: block;
    margin: 0px;
  }

  .mobUiCover {
    display: flex;
    width: 95%;
    gap: 6px;
    flex-wrap: wrap;
  }


  .galleryLinkMob {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .priceGroup {
    flex-wrap: wrap;
  }

  .priceButton>* {
    padding: 5px;
  }



  .spLabel2 {
    display: block;
  }

  .carousel {
    max-width: 50vw;
  }

  .thumbDivMob {
    max-width: 50vw;
    width: calc(50vw);
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 2px;
    align-self: center;
    display: flex;
    justify-content: space-around;
  }

  .thumbDivHide {
    display: none;
  }

  /* .thumbDiv {
    display: flex;
    max-height: 150px;
    align-items: center;
  } */

  .thumbnail {
    width: calc(100vw/21);
    height: calc(100vw/21*1.15);
  }

  .thumbnailActive {
    width: calc(100vw/21);
    height: calc(100vw/21*1.15);
  }

  .howtoStep {
    flex: 1 0 34%;
  }

  .thumbbut {
    align-self: center;
  }

  .hideMob1,
  .thumbDivHide,
  .hideSmallMob,
  .galleryLink {
    display: none
  }
}

.h4mpobile {
  display: none;
}


@media screen and (max-width: 800px) {
  .benefitItem {
    flex: 1 0 51%;
    max-width: 80%;
    border-color: #6941d0;
  }

  .bottomLinks {
    flex-direction: column;
  }

  .breakH2 {
    display: inline;
  }



  .wraph2 {
    max-width: 100%;
  }

  .inblue2Strong {
    gap: 5px;
    font-size: 16px;
    justify-content: center;
  }

  .googleRev {
    display: none;
  }

  .googleRevMob {
    width: 100%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mobUiCover {
    display: flex;
    width: 95%;
    gap: 6px;

  }

  .featureWrap {
    max-width: 85vw;
  }


  .priceGroup {
    flex-direction: column
  }

  .wraph1 {
    display: none
  }

  .wraph1Mobile {
    display: block;
    font-size: 15px;
  }

  .hideMob1,
  .thumbDivHide,
  .hideSmallMob,
  .galleryLink {
    display: none
  }


  .thumbDivMob {
    max-width: 100%;
    width: 100%;
  }

  .howitworks1Mob {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background: rgb(237, 237, 237);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-left: 20px;
    margin-right: 20px;
  }

  .inblue {
    font-size: 13px;

  }

  .inblue2 {
    width: 100%;
    font-size: 15px;
    box-sizing: border-box;
    gap: 0px;
  }

  .inblue2Strong>span {

    gap: 0px;
    margin: 0xp;
    padding: 0px;
  }

  .inblue2Strong {
    width: 100%;
    max-width: 100vw;
  }

  .featureWrap {
    box-sizing: border-box;
    max-width: 100vw;
  }

  .thumbBut {
    align-self: flex-start;
    width: calc(100vw/12);
    margin: 1px;
    height: calc(100vw/10*1.15);
  }

  .howitworks1 {
    display: none;

  }

  .title {
    justify-content: center;
    flex-wrap: nowrap;

  }



  .sampleButtons {
    box-sizing: border-box;
    flex-direction: column;
    width: 95%;
    box-sizing: border-box;

  }

  .buttonWrapSamples {
    width: 100%;
  }


  .coverImage {
    width: calc(100vw - 20px);
    height: calc((100vw - 20px)*1.15);
    margin: 0px;
    box-shadow: 0px 5px 10px 3px rgb(49, 49, 49);

  }

  .howtoStep {
    margin: 0px;
  }

  .howtoStep {
    width: 100%;
    box-sizing: border-box;
    align-self: center;
    flex-direction: row;
    justify-content: space-around;
    gap: 50px;

  }

  .howtoStep>p {
    text-align: left;
    width: 70%;
  }

  .leftSec {
    margin: 0px;
    align-items: center;
    box-sizing: border-box;
    max-width: 100vw;

  }

  .serviceSpanEx {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .itemsGroup {
    justify-content: space-evenly;
    flex: 0;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    align-self: center;
    width: 100%;
    border-bottom-left-radius: 0px;
  }

  .priceButton {
    box-sizing: border-box;
    width: 100%;
  }




  .wrap>h2 {
    font-size: 0.90rem;
  }

  .wrap>h2>i {
    background-size: 10px 50px, calc(100% - 20px) 50px, 10px 50px;
  }


  .featureULItem {
    flex: 1 0 34%;
    padding: 15px;
    margin: 5px;
  }

  .testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    margin: 20px;
  }



  .thumbDiv {
    max-height: none;
    max-width: 100vw !important;
    width: calc(100vw);
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 2px;
    align-self: center;
    display: flex;
    justify-content: space-around;
  }

  .thumbnail {
    width: calc(100vw/10) !important;
    height: calc(100vw/10*1.15) !important;
    padding: 0px;
    box-sizing: border-box;
  }

  .thumbnailActive {
    width: calc(100vw/10);
    height: calc(100vw/10*1.15);
    padding: 0px;
    box-sizing: border-box;
  }


  .rightFirstSec {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    gap: 30px;
    margin-top: 0px;
    display: none;
  }

  .getStartedSection {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }


  .carousel {
    width: 100%;
    /* height: 875px; */
    /* min-height: 500px; */
    max-width: 100%;
    box-sizing: border-box;
  }


  .wrap>h1 {
    text-align: center;
  }

  .describe {
    text-align: center;
    margin-top: 0px;
  }

  .howtoBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    gap: 15px;
  }




  .section1 {
    flex-direction: column;
    align-items: center;
  }

}

@media screen and (max-width: 650px) {
  .h4mpobile {
    display: block;
  }

  .seenOn {
    width: 90px;
    height: auto;
  }


  .featureULItem {
    flex: 1 0 34%;
    font-size: 13px;
  }

  .iframeVideo {
    width: calc(90vw);
    height: calc(90vw/1.7777);
    /* height: auto; */
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    margin: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(236, 236, 236);
    border-radius: 15px;
  }

}