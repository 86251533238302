.spLabel {
  align-self: center;
  margin: 5px;
  font-weight: bold;
}

.serviceSpanExHide {
  display: none;
}

.h4>p {
  margin: 3px;
}

.serviceSpanEx {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: white;
  width: 100%;
  max-width: 95vw;
  box-sizing: border-box;
  /* border: 1px solid red; */
}


.priceGroup {
  display: flex;
  width: 100%;
  gap: 30px;
}

.bundleWrap {
  flex: 1 0 24%;
  text-align: center;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border:1px solid red; */
}

.bundleWrapVoucher {
  width: 50%;
  text-align: center;
  margin: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border:1px solid red; */
  box-sizing: border-box;
}



.bundleWrap>h4 {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.originalPrice {
  /* text-decoration: line-through; */
  color: rgba(74, 179, 18, 0.909);
  margin: 0px;

  text-align: center;
  font-size: 1.2rem;
  border-bottom: 2px solid rgba(84, 184, 30, 0.839);
  padding: 2px;
}

.priceButton {
  width: 100%;
  box-sizing: border-box;
  border: 3px solid rgb(26, 97, 190);
  margin: 10px;
  border-radius: 10px;
}

.priceButton>h4 {
  text-align: center;
}

.itemsGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px;
  padding: 5px;
  justify-content: space-evenly;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(90deg, #1e90c9 10%, #1cca8ac1 90%);
}


.item {
  align-self: center;
  text-align: center;
  text-decoration: none;
  list-style-type: none;
  border-radius: 5px;
  background-color: white;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 34%;
  padding: 10px;
  margin: 10px;
}

.priceDiv {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin: 10px;
  gap: 20px;
  padding: 25px;
}

.promoPrice {
  color: #0b0e0cc3;
  text-align: center;
  border-radius: 5px;
  margin: 0px;
  font-size: 1.2rem;
  background-color: white;
}

.ppSup {
  font-size: 0.7rem;
}

.perPetUSD {
  text-align: left;
  font-size: 11px;
}

.navlinkStarted {
  display: flex;
  text-decoration: none !important;
  border-radius: 25px;
  /* background: linear-gradient(90deg, #d3e01c 50%, #f69103c1 90%); */
  align-self: center;
  margin: 5px;
}


.getStarted {
  width: 100%;
  height: 60px;
  border-radius: 15px;
  background: linear-gradient(90deg, #1CB5E0 10%, #94116fc1 90%);
  border: 1px solid #f5f5f5;
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin: 6px;
  cursor: pointer;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;

  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.getStarted:hover {
  transform: scale(1.05);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 25px;
  height: 25px;
  filter: invert(1);
}

.icon1 {
  width: 25px;
  height: 25px;
  margin-right: 6px;
}

.itemSmall {
  align-self: center;
  text-align: center;
  text-decoration: none;
  align-items: center;
  list-style-type: none;
  border-radius: 5px;
  background-color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex: 1 0 34%;
  padding: 3px;
  margin: 3px;
}



/* ! */
@media screen and (max-width: 800px) {
  .icon1 {
    margin-right: 3px;
  }

  .bundleWrapVoucher {
    width: 90%;
    max-width: 90%;

  }

  .serviceSpanEx {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .priceGroup {
    flex-direction: column;
    gap: 0px;
  }

  .priceButton {
    box-sizing: border-box;
    width: 100%;
  }

  .itemsGroup {
    justify-content: space-evenly;
    flex: 0;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    align-self: center;
    width: 100%;
    border-bottom-left-radius: 0px;
  }

  .navlinkStarted {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .getStarted {
    align-self: center;
    padding: 15px;
    font-size: 17px;
    margin: 5px;
    height: 50px;
  }

  .includedMob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    flex-wrap: wrap;

  }

  .includedMob>* {
    /* border: 1px solid red; */
  }

}


/* ! */
@media screen and (max-width: 1200px) {
  .spLabel2 {
    display: block;
  }

  .priceGroup {
    flex-wrap: wrap;
  }

  .priceButton>* {
    padding: 5px;
  }



}